import React, { useState } from 'react';
import { Grid } from '@mui/material'
import images1 from './image/1.jpg'
import images2 from './image/2.jpg'
import images3 from './image/3.jpg'
import images4 from './image/4.jpg'
import images5 from './mope.jpg'
import images6 from './image/5.jpg'
import images7 from './image/6.jpg'
import images8 from './image/7.jpg'
import images9 from './image/8.jpg'


import tops from './blv-main.png'

import logo from './blv-logo.png'


const SplineIframe = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const coinAddress = '0x';

    navigator.clipboard.writeText(coinAddress)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500); // Reset "Copied" message after 1.5 seconds
      })
      .catch(err => console.error('Error copying: ', err));
  };
  return (
    <div>  
      <div className='container wrapper'>
         <br/>   
            <div className='buttontopplacemc'> 
               <img src={logo} className='tops_monkl'/>
            </div>
         <br/>  
         <Grid container spacing={2} style={{ maxWidth: '1400px', margin: 'auto'}}>
         <br/><br/>
         <Grid item md={3} lg={3} xs={6} sm={6}>
            <br/>
            <br/>
            <a href='https://app.astroport.fi/swap' target='_blank' style={{ textDecoration: 'none'}}>
               <div  className='buttontopplacemc'>
                  <button  class="buttontopcc">
                     <p>Astroport</p>
                     </button>
               </div>
            </a>
         </Grid>
         <Grid item md={3} lg={3} xs={6} sm={6}>
            <br/>
            <br/>
            <a href='/' target='_blank' style={{ textDecoration: 'none'}}>
               <div  className='buttontopplacemc'>
                  <button  class="buttontopcc">
                    <p>Chart</p>
                  </button>
               </div>
            </a>
         </Grid>
         <Grid item md={3} lg={3} xs={6} sm={6}>
            <br/>
            <br/>
            <a href='https://t.me/injcelportal' target='_blank' style={{ textDecoration: 'none'}}>
               <div  className='buttontopplacemc' >
                  <button  class="buttontopcc">
                     <p>Telegram</p>
                  </button>
               </div>
            </a>
         </Grid>
         <Grid item md={3} lg={3} xs={6} sm={6}>
            <br/>
            <br/>
            <a href='https://twitter.com/injcel' target='_blank' style={{ textDecoration: 'none'}}>
               <div  className='buttontopplacemc'>
                  <button  class="buttontopcc">
                     <p>Twitter</p>
                  </button>
               </div> 
            </a>
            <br/>
            <br/>
         </Grid>
         <br/>
         <br/>
         <Grid item md={12} lg={12} xs={12} sm={12} class=" blacktext">
            <div className='buttontopplacemc'> 
               <img src={tops} className='tops_monk'/>
            </div>
         </Grid>
         <br/><br/><br/><br/>
         <Grid item md={12} lg={12} xs={12} sm={12} class="blacktextm">
            <div className='buttontopplacemc' style={{textAlign:"center"}}> 
               <div className='class="blacktextm2"'>
               <p style={{ fontSize: '24px', fontWeight: 'normal', width: '80%', margin: 'auto'}}>
               An "INJcel" epitomizes a hyper-focused advocate of Injective (INJ), a specific cryptocurrency. This persona is characterized by an unwavering, almost zealous loyalty to INJ, coupled with an outright rejection of all other forms of cryptocurrency. Their allegiance to Injective transcends mere preference; it's an all-encompassing commitment that elevates INJ to a singular status of superiority in their eyes.
               <br/><br/>
               The worldview of an INJcel is steeped in a profound conviction about the unparalleled merits of Injective. They perceive INJ as the pinnacle of cryptocurrency innovation—superior in its technology, growth potential, security measures, and overall utility. This intense belief system leads them to dismiss other digital currencies categorically, often regarding them as inferior or even detrimental to the cryptocurrency ecosystem.
               <br/><br/>
               INJcels are not passive in their advocacy. They actively engage in community dialogues, often dominating forums and social media spaces dedicated to cryptocurrency discussions. Their interactions are marked by a fervent defense of INJ, and they are quick to challenge any comparative analysis that doesn't place Injective at the forefront.
               <br/><br/>
               From an investment standpoint, INJcels are singularly focused. Their portfolios are exclusively composed of INJ, eschewing the common strategy of diversification. Their commitment often extends to evangelical efforts, where they seek to convert others to their perspective, highlighting the perceived flaws in other cryptocurrencies while extolling the virtues of Injective.
               <br/><br/>
               In essence, the INJcel is a distinctly niche figure within the broader cryptocurrency landscape, embodying a level of devotion and exclusivity that sets them apart. Their identity is intrinsically linked to their unshakeable belief in Injective's supremacy, rendering them a unique and intensely passionate subset of digital currency enthusiasts.
               <br/>
               <br/>
               </p>
               </div>
            </div>
         </Grid>
         <Grid item md={12} lg={12} xs={12} sm={12} class="blacktextm">
            <div className='buttontopplacemc' style={{textAlign:"center"}}> 
               <p className="blacktextmca">factory-inj1597pysn2u56ggcckykt2zzjuqkeezy5yxfujtj-INJcel</p>
            </div>
         </Grid>
         <br/> <br/> 
         <Grid item md={6} lg={6} xs={6} sm={6} className='centeritalltheButton'>
            <br/>
            <br/>
            <img src={images1} style={{width:"100%" , border:"2px solid #000"}}/>
         </Grid>
         <Grid item md={6} lg={6} xs={6} sm={6} className='centeritalltheButton'>
            <br/>
            <br/>
            <img src={images2} style={{width:"100%" , border:"2px solid #000"}}/>
         </Grid>
         <Grid item md={6} lg={6} xs={6} sm={6} className='centeritalltheButton'>
            <br/>
            <br/>
            <img src={images3} style={{width:"100%" , border:"2px solid #000"}}/>
         </Grid>
         <Grid item md={6} lg={6} xs={6} sm={6} className='centeritalltheButton'>
            <br/>
            <br/>
            <img src={images4} style={{width:"100%" , border:"2px solid #000"}}/>
         </Grid>
         <br/>
         <br/> 
         <br/>
         <br/>                
         <Grid item md={12} lg={12} xs={12} sm={12} className='centeritalltheButton'>
            <br/>
            <br/>
            <img src={images5} style={{width:"100%" , border:"2px solid #000"}}/>
         </Grid>
         <Grid item md={6} lg={6} xs={6} sm={6} className='centeritalltheButton'>
            <br/>
            <br/>
            <img src={images6} style={{width:"100%" , border:"2px solid #000"}}/>
         </Grid>
         <Grid item md={6} lg={6} xs={6} sm={6} className='centeritalltheButton'>
            <br/>
            <br/>
            <img src={images7} style={{width:"100%" , border:"2px solid #000"}}/>
         </Grid>
         <Grid item md={6} lg={6} xs={6} sm={6} className='centeritalltheButton'>
            <br/>
            <br/>
            <img src={images8} style={{width:"100%" , border:"2px solid #000"}}/>
         </Grid>
         <Grid item md={6} lg={6} xs={6} sm={6} className='centeritalltheButton'>
            <br/>
            <br/>
            <img src={images9} style={{width:"100%" , border:"2px solid #000"}}/>
         </Grid>
         </Grid>     
         <Grid item md={12} lg={12} xs={12} sm={12} class="blacktextm">
         </Grid>
      </div>
    </div>
  );
};

export default SplineIframe;
