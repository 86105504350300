import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import Home from "./pages/HomeX";

import theme from "./Theme";
import "rsuite/styles/index.less";
import "./App.css";
import "./style.scss";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay (you can replace this with actual data fetching or any async operation)
    const timeout = setTimeout(() => {
      setLoading(false); // Set loading to false after the timeout
    },3000); // Replace 2000 with the desired delay in milliseconds

    // Clean up the timeout on unmount or when loading is done
    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>$INJCEL</title>
        <meta name="An INJcel epitomizes a hyper-focused advocate of Injective (INJ), a specific cryptocurrency" content="INJCEL" />
      </Helmet>
      <Router>
          <Routes>
            <>
            <Route path="/" exact={true} element={<Home />} />

            </>
          </Routes>
        </Router>
    </ThemeProvider>
  );
}

export default App;
