import React from "react";
import { useMediaQuery } from "@mui/material";
import { Grid } from '@mui/material'
import Home from "../component/Home/Home";
function YourComponent() {
  const desktopBreakpoint = useMediaQuery("(min-width: 1100px)");
  const tabletBreakpoint = useMediaQuery("(max-width: 1449px) and (min-width: 1100px)");
  const mobileBreakpoint = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {desktopBreakpoint && (
        <div>
          {/* Content for desktop view */}
      <Home/>
      <div className="container">  <Grid container spacing={2}>

</Grid>



  
  <br/>
   </div>
        </div>
      )}
      {tabletBreakpoint && (
        <div>
         <div className="container">  <Grid container spacing={2}>

<Grid item md={12} lg={12} xs={12} sm={12} className="buttontopplacemcf" > <p>$INJcel is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. the coin is completely useless and for entertainment purposes only.</p></Grid>

</Grid>



  
  <br/>
   </div>
        </div>
      )}
      {mobileBreakpoint && (
        <div>
          <Home/>
          <br/> <br/>
          <div className="container">  <Grid container spacing={2}>

<Grid item md={12} lg={12} xs={12} sm={12} className="buttontopplacemcf" > <p>$Injcel is a meme coin with no intrinsic value or expectation of financial return. There is no formal team or roadmap. the coin is completely useless and for entertainment purposes only.</p></Grid>

</Grid>



  
  <br/>
   </div>
        </div>
      )}
      
    </div>
  );
}

export default YourComponent;
